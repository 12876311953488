import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../layouts";
import styled from "styled-components";

const Cards = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media only screen and (min-width: 500px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 850px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1140px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Card = styled.div`
  text-decoration: none;
  color: #444;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  p {
    margin-top: 0 !important;
  }

  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }
`;

const Customer = ({ data }) => (
  <Layout>
    <h1>Kunder</h1>
    <Cards>
      {data.allAirtable.edges.map((edge) => (
        <Card key={edge.node.id}>
          <p>
            <Link to={edge.node.fields.slug}>
              <strong>{edge.node.data.Name}</strong>
            </Link>
          </p>
          {edge.node.data.Projects && edge.node.data.Projects.length > 0 ? (
            <div>
              <ul>
                {edge.node.data.Projects.map((project) =>
                  project.data.Has_active_assignments
                    ? project.data.Assignments.map((assignment) =>
                        assignment.data.Active
                          ? assignment.data.Person.map((person) => (
                              <li>{person.data.Name}</li>
                            ))
                          : null
                      )
                    : null
                )}
              </ul>
            </div>
          ) : null}
        </Card>
      ))}
    </Cards>
  </Layout>
);
export default Customer;

export const query = graphql`
  query {
    allAirtable(
      filter: {
        table: { eq: "Customers" }
        data: { Has_active_projects: { gt: 0 } }
      }
      sort: { fields: [data___Name], order: ASC }
    ) {
      edges {
        node {
          id
          data {
            Name
            Projects {
              data {
                Has_active_assignments
                Assignments {
                  data {
                    Active
                    Person {
                      data {
                        Name
                      }
                    }
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
